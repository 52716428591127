import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  contactUsScrollToForm: false,
  notification: {
    show: false,
    message: "",
    type: "success",
  },
  bookingCategories: [],
}

export const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setContactUseScroll: (state, { payload }) => {
      state.contactUsScrollToForm = payload
    },
    setNotification: (state, { payload }) => {
      state.notification = payload
    },
    setBookingCategories: (state, { payload }) => {
      state.bookingCategories = payload || []
    },
  },
})

export const { setContactUseScroll, setNotification, setBookingCategories } =
  rootSlice.actions

export default rootSlice.reducer
