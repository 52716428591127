exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about-us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-battersea-pharmacy-index-jsx": () => import("./../../../src/pages/battersea-pharmacy/index.jsx" /* webpackChunkName: "component---src-pages-battersea-pharmacy-index-jsx" */),
  "component---src-pages-book-an-appointment-index-jsx": () => import("./../../../src/pages/book-an-appointment/index.jsx" /* webpackChunkName: "component---src-pages-book-an-appointment-index-jsx" */),
  "component---src-pages-book-an-appointment-location-index-jsx": () => import("./../../../src/pages/book-an-appointment/location/index.jsx" /* webpackChunkName: "component---src-pages-book-an-appointment-location-index-jsx" */),
  "component---src-pages-book-an-appointment-step-1-index-jsx": () => import("./../../../src/pages/book-an-appointment/step1/index.jsx" /* webpackChunkName: "component---src-pages-book-an-appointment-step-1-index-jsx" */),
  "component---src-pages-book-an-appointment-step-2-index-jsx": () => import("./../../../src/pages/book-an-appointment/step2/index.jsx" /* webpackChunkName: "component---src-pages-book-an-appointment-step-2-index-jsx" */),
  "component---src-pages-book-an-appointment-step-3-index-jsx": () => import("./../../../src/pages/book-an-appointment/step3/index.jsx" /* webpackChunkName: "component---src-pages-book-an-appointment-step-3-index-jsx" */),
  "component---src-pages-book-an-appointment-step-4-index-jsx": () => import("./../../../src/pages/book-an-appointment/step4/index.jsx" /* webpackChunkName: "component---src-pages-book-an-appointment-step-4-index-jsx" */),
  "component---src-pages-book-an-appointment-step-5-index-jsx": () => import("./../../../src/pages/book-an-appointment/step5/index.jsx" /* webpackChunkName: "component---src-pages-book-an-appointment-step-5-index-jsx" */),
  "component---src-pages-book-an-appointment-step-6-index-jsx": () => import("./../../../src/pages/book-an-appointment/step6/index.jsx" /* webpackChunkName: "component---src-pages-book-an-appointment-step-6-index-jsx" */),
  "component---src-pages-book-an-appointment-step-7-index-jsx": () => import("./../../../src/pages/book-an-appointment/step7/index.jsx" /* webpackChunkName: "component---src-pages-book-an-appointment-step-7-index-jsx" */),
  "component---src-pages-booking-api-index-jsx": () => import("./../../../src/pages/booking-api/index.jsx" /* webpackChunkName: "component---src-pages-booking-api-index-jsx" */),
  "component---src-pages-booking-pharmacy-addon-index-jsx": () => import("./../../../src/pages/booking-pharmacy/addon/index.jsx" /* webpackChunkName: "component---src-pages-booking-pharmacy-addon-index-jsx" */),
  "component---src-pages-booking-pharmacy-confirmed-booking-index-jsx": () => import("./../../../src/pages/booking-pharmacy/confirmed-booking/index.jsx" /* webpackChunkName: "component---src-pages-booking-pharmacy-confirmed-booking-index-jsx" */),
  "component---src-pages-booking-pharmacy-index-jsx": () => import("./../../../src/pages/booking-pharmacy/index.jsx" /* webpackChunkName: "component---src-pages-booking-pharmacy-index-jsx" */),
  "component---src-pages-booking-pharmacy-step-2-index-jsx": () => import("./../../../src/pages/booking-pharmacy/step-2/index.jsx" /* webpackChunkName: "component---src-pages-booking-pharmacy-step-2-index-jsx" */),
  "component---src-pages-booking-pharmacy-step-3-index-jsx": () => import("./../../../src/pages/booking-pharmacy/step-3/index.jsx" /* webpackChunkName: "component---src-pages-booking-pharmacy-step-3-index-jsx" */),
  "component---src-pages-booking-pharmacy-step-4-index-jsx": () => import("./../../../src/pages/booking-pharmacy/step-4/index.jsx" /* webpackChunkName: "component---src-pages-booking-pharmacy-step-4-index-jsx" */),
  "component---src-pages-conditions-and-procedures-index-jsx": () => import("./../../../src/pages/conditions-and-procedures/index.jsx" /* webpackChunkName: "component---src-pages-conditions-and-procedures-index-jsx" */),
  "component---src-pages-contact-us-index-jsx": () => import("./../../../src/pages/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-contact-us-index-jsx" */),
  "component---src-pages-cookies-index-jsx": () => import("./../../../src/pages/cookies/index.jsx" /* webpackChunkName: "component---src-pages-cookies-index-jsx" */),
  "component---src-pages-feedback-index-jsx": () => import("./../../../src/pages/feedback/index.jsx" /* webpackChunkName: "component---src-pages-feedback-index-jsx" */),
  "component---src-pages-for-clinicians-index-jsx": () => import("./../../../src/pages/for-clinicians/index.jsx" /* webpackChunkName: "component---src-pages-for-clinicians-index-jsx" */),
  "component---src-pages-gdpr-index-jsx": () => import("./../../../src/pages/gdpr/index.jsx" /* webpackChunkName: "component---src-pages-gdpr-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-patient-information-index-jsx": () => import("./../../../src/pages/new-patient-information/index.jsx" /* webpackChunkName: "component---src-pages-new-patient-information-index-jsx" */),
  "component---src-pages-new-private-healthcare-at-battersea-power-station-index-jsx": () => import("./../../../src/pages/new-private-healthcare-at-battersea-power-station/index.jsx" /* webpackChunkName: "component---src-pages-new-private-healthcare-at-battersea-power-station-index-jsx" */),
  "component---src-pages-our-specialists-index-jsx": () => import("./../../../src/pages/our-specialists/index.jsx" /* webpackChunkName: "component---src-pages-our-specialists-index-jsx" */),
  "component---src-pages-our-values-index-jsx": () => import("./../../../src/pages/our-values/index.jsx" /* webpackChunkName: "component---src-pages-our-values-index-jsx" */),
  "component---src-pages-patient-information-index-jsx": () => import("./../../../src/pages/patient-information/index.jsx" /* webpackChunkName: "component---src-pages-patient-information-index-jsx" */),
  "component---src-pages-preparing-for-your-visit-index-jsx": () => import("./../../../src/pages/preparing-for-your-visit/index.jsx" /* webpackChunkName: "component---src-pages-preparing-for-your-visit-index-jsx" */),
  "component---src-pages-pricing-and-payment-index-jsx": () => import("./../../../src/pages/pricing-and-payment/index.jsx" /* webpackChunkName: "component---src-pages-pricing-and-payment-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-terms-and-conditions-index-jsx": () => import("./../../../src/pages/terms-and-conditions/index.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-jsx" */),
  "component---src-pages-terms-and-conditions-introduction-services-index-jsx": () => import("./../../../src/pages/terms-and-conditions/introduction-services/index.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-introduction-services-index-jsx" */),
  "component---src-pages-terms-and-conditions-medical-and-surgical-procedures-index-jsx": () => import("./../../../src/pages/terms-and-conditions/medical-and-surgical-procedures/index.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-medical-and-surgical-procedures-index-jsx" */),
  "component---src-pages-test-index-jsx": () => import("./../../../src/pages/test/index.jsx" /* webpackChunkName: "component---src-pages-test-index-jsx" */),
  "component---src-pages-unwavering-care-index-jsx": () => import("./../../../src/pages/unwavering-care/index.jsx" /* webpackChunkName: "component---src-pages-unwavering-care-index-jsx" */),
  "component---src-templates-service-category-jsx": () => import("./../../../src/templates/service-category.jsx" /* webpackChunkName: "component---src-templates-service-category-jsx" */),
  "component---src-templates-service-diagnosis-and-treatment-jsx": () => import("./../../../src/templates/service-diagnosis-and-treatment.jsx" /* webpackChunkName: "component---src-templates-service-diagnosis-and-treatment-jsx" */),
  "component---src-templates-service-jsx": () => import("./../../../src/templates/service.jsx" /* webpackChunkName: "component---src-templates-service-jsx" */),
  "component---src-templates-service-symptoms-and-causes-jsx": () => import("./../../../src/templates/service-symptoms-and-causes.jsx" /* webpackChunkName: "component---src-templates-service-symptoms-and-causes-jsx" */),
  "component---src-templates-specialist-category-jsx": () => import("./../../../src/templates/specialist-category.jsx" /* webpackChunkName: "component---src-templates-specialist-category-jsx" */),
  "component---src-templates-specialist-jsx": () => import("./../../../src/templates/specialist.jsx" /* webpackChunkName: "component---src-templates-specialist-jsx" */)
}

